import { MouseEvent } from 'react'

interface ButtonModernProps {
  children: React.ReactNode
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  className?: string
  type?: 'submit' | 'button'
}

const ButtonModern = ({ children, onClick, className, type = 'button' }: ButtonModernProps) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`p-6 py-4 rounded font-semibold text-lg leading-5 cursor-pointer transition-all mt-4 bg-gradient-to-b from-lightSkyBlue to-purple hover:from-purple hover:to-lightSkyBlue ${
        className || ''
      }`}
    >
      {children}
    </button>
  )
}

export default ButtonModern
