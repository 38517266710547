import Video from '@/components/Video/Video'
import { BaseSectionItem } from '@/types/homeContent.model'

export interface VideoTemplateProps extends BaseSectionItem {
  isVisible: boolean
}

const VideoTemplate = (props: VideoTemplateProps) => {
  return (
    <div className="carousel-item--video flex flex-col relative items-center min-w-full">
      <Video {...props} />
    </div>
  )
}

export default VideoTemplate
