import { CarouselProps, CarouselLayoutType } from '@/components/Carousel/types'
import Grid from '@/components/Carousel/Layouts/Grid'
import Wide from '@/components/Carousel/Layouts/Wide'

const Carousel = (props: CarouselProps) => {
  if (!props.items.length) {
    return null
  }

  const renderLayout = (carouselLayoutType: CarouselLayoutType) => {
    if (carouselLayoutType === 'GRID') {
      return <Grid {...props} />
    }
    if (carouselLayoutType === 'WIDE') {
      return <Wide {...props} />
    }
  }

  return (
    <div className="carousel pt-16 pb-28">
      <div className="container-padded flex justify-center">{renderLayout(props.layout)}</div>
    </div>
  )
}

export default Carousel
