import { CollectionItem } from '@/components/Collection/types'
import CarouselItem from '@/components/Collection/Templates/StoryWithCarousel/CarouselItem'
import useCarousel from '@/components/Carousel/useCarousel'
import useSwipe from '@/components/Carousel/useSwipe'

export interface CarouselProps {
  items: CollectionItem[]
}

const Carousel = (props: CarouselProps) => {
  const { sliderRef, pageNumber, currentPage, next, prev, move, isActive } = useCarousel()
  const { handleTouchStart, handleTouchMove } = useSwipe()

  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={(e) => handleTouchMove(e, prev, next)}
      className="carousel"
      ref={sliderRef}
    >
      <div className="carousel__container mx-auto relative overflow-hidden">
        <div className="carousel__items-container absolute flex flex-row transition-left ease-in-out duration-500">
          {props.items.map((item: CollectionItem, index: number) => (
            <CarouselItem key={index} {...item} />
          ))}
        </div>
      </div>
      <div className="carousel__pager mt-10">
        <div className="carousel__bubbles text-center">
          {[...Array(pageNumber)].map((_, index: number) => (
            <span
              key={index}
              onClick={() => (!isActive(index, currentPage) ? move(index) : null)}
              className={`rounded-xl bg-white inline-block w-3 h-3 mr-5 ${
                !isActive(index, currentPage)
                  ? 'bg-opacity-50 border-opacity-50 cursor-pointer'
                  : 'cursor-default'
              }`}
            ></span>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Carousel
