import { SingleTileImageProps } from '@/components/Tiles/SingleTileImage'
import urlHelper from '@mpe/api-client/utils/urlHelper'
import { getEntityType } from '@/utils/index'

const entryImageFields: { [key: string]: string } = {
  cover: 'coverImageUrl',
  wide: 'wideImageUrl',
  square: 'squareImageUrl',
  portrait: 'portraitImageUrl'
}

const fortepanImageSourceFields: { [key: string]: string } = {
  cover: 'fortepanCoverImageSource',
  wide: 'fortepanWideImageSource',
  square: 'fortepanSquareImageSource',
  portrait: 'fortepanPortraitImageSource'
}

const entryMtvaImageFields: { [key: string]: string } = {
  cover: 'coverMtvaImageUrl',
  wide: 'wideMtvaImageUrl',
  square: 'squareMtvaImageUrl',
  portrait: 'portraitMtvaImageUrl'
}

const entryMtvaImagePositionFields: { [key: string]: string } = {
  cover: 'coverMtvaImagePosition',
  wide: 'wideMtvaImagePosition',
  square: 'squareMtvaImagePosition',
  portrait: 'portraitMtvaImagePosition'
}

export function getTileImageProps(
  tileType: 'cover' | 'wide' | 'square' | 'portrait',
  entity: any
): SingleTileImageProps {
  const idealImageUrl = entity[entryImageFields[tileType]]
  const squareImageUrl = entity[entryImageFields['square']]
  const fortepanImageSource = entity[fortepanImageSourceFields[tileType]]
  const mtvaImageHash = entity[entryMtvaImageFields[tileType]]
  const mtvaImageUrl = mtvaImageHash ? urlHelper.getMtvaUrl(mtvaImageHash, 'lg') : null
  const mtvaImagePosition = entity[entryMtvaImagePositionFields[tileType]]
  const entityType = getEntityType(entity)
  const isPublication = 'Publication' === entityType

  let defaultImageUrl = null
  if ('Image' === entityType) {
    defaultImageUrl = entity.fileName
  }

  if ('Video' === entityType) {
    defaultImageUrl = entity.previewImages?.length ? entity.previewImages[0] : null
  }

  if (entity.defaultImageUrl) {
    defaultImageUrl = entity.defaultImageUrl
  }

  const customImageSource =
    entity.portraitImageSource ||
    entity.squareImageSource ||
    entity.wideImageSource ||
    entity.coverImageSource

  return {
    tileType,
    idealImageUrl,
    squareImageUrl,
    mtvaImageUrl,
    mtvaImagePosition,
    mtvaImageHash,
    fortepanImageSource,
    defaultImageUrl,
    isPublication,
    classes: {},
    customImageSource
  }
}

export function getAlbumNameFromImageName(imageName: string) {
  return imageName.split('(').slice(0, -1).join('(').trim()
}
