const BlogHeader = () => {
  return (
    <div className="blog-header w-full bg-gradient-to-l from-lightSkyBlue to-purple">
      <div className="container-padded lg:bg-header bg-right bg-no-repeat bg-contain">
        <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-2 py-10">
          <div className="blog-header__text lg:col-span-2 xl:col-span-1">
            <p className="blog-header__subtitle font-roboto font-medium text-lg">
              Érdekességek, adatok, koncertek a magyar popkultúra világából
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogHeader
