import { useRouter } from 'next/router'
import { getEntityType, getEntityUrl } from '@/utils'
import { getTileImageProps } from '@/utils/image'
import { CollectionItem } from '@/components/Collection/types'
import SingleTileImage from '@/components/Tiles/SingleTileImage'
import imageClasses from '@/components/Tiles/singleTileImage.module.css'
import { gradients } from '@/components/Tiles/consts'
import homepage from '../../../../styles/homepage.module.css'

const CarouselItem = (props: CollectionItem) => {
  const router = useRouter()
  const onClick = () => {
    router.push(getEntityUrl(props.entity))
  }

  if (!props.entity) {
    return null
  }

  const bg = `bg-gradient-to-tr ${gradients[getEntityType(props.entity)] || gradients.default}`
  const classes = {
    ...imageClasses,
    watermarkWrapper: homepage['tile-watermark-wrapper'] + ' ' + bg,
    watermarkImage: homepage['tile-watermark-image']
  }

  return (
    <div className="collection__carousel">
      <div className="carousel-item pb-2 px-2">
        <div className="carouse-item__container cursor-pointer" onClick={onClick}>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-14 lg:gap-28">
            <div className="collection__description">
              <h2 className="collection__title font-gilroy font-extrabold text-5xl leading-tight mb-5">
                {props.title}{' '}
              </h2>
              {props.subtitle && props.subtitle !== '' && (
                <p className="carousel-item__date_from text-white text-opacity-50 font-roboto font-semibold text-lg text-justify leading-5 mb-4">
                  {props.subtitle}
                </p>
              )}
              {props.lead && props.lead !== '' && (
                <p className="collection__lead font-roboto font-bold text-lg mb-5 text-justify">
                  {props.lead}
                </p>
              )}
            </div>
            <div
              className={`carousel-item__image mb-4 relative`}
              style={{ paddingBottom: '68.3760684%' }}
            >
              <div
                className={`${homepage['tile-hover']} ${homepage['tile-overlay']} absolute w-full h-full`}
              >
                <SingleTileImage {...getTileImageProps('wide', props.entity)} classes={classes} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CarouselItem
