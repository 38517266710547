import PagerButton from '@/components/Carousel/Components/PagerButton'
import useCarousel from '@/components/Carousel/useCarousel'
import PagerLines from '@/components/Carousel/Components/PagerLines'
import useSwipe from '@/components/Carousel/useSwipe'
import VideoTemplate from '@/components/Carousel/Templates/VideoTemplate'

import { CarouselItem, CarouselProps, CarouselWideTemplateType } from '@/components/Carousel/types'

const Wide = (props: CarouselProps) => {
  const { sliderRef, pageNumber, currentPage, next, prev, move, isAtStart, isAtEnd, isActive } =
    useCarousel()
  const { handleTouchStart, handleTouchMove } = useSwipe()

  const styles = {
    pager: { top: 'calc(50% - 22px)' }
  }

  const renderTemplate = (
    templateType: CarouselWideTemplateType,
    item: CarouselItem,
    index: number
  ) => {
    if (templateType === 'VIDEO') {
      return <VideoTemplate {...item} isVisible={currentPage === index} />
    }
  }

  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={(e) => handleTouchMove(e, prev, next)}
      className="carousel__layout--wide w-full lg:w-3/4"
      ref={sliderRef}
    >
      <h2 className="font-gilroy font-extrabold text-4xl sm:text-5xl text-center max-w-2xl ml-auto mr-auto mb-10">
        {props.title}
      </h2>
      <div className="w-full relative">
        <div
          style={styles.pager}
          className="carousel__pager--left absolute left-3 z-20 hidden sm:block"
        >
          <PagerButton
            onClick={() => (!isAtStart() ? prev() : null)}
            type="PREV"
            disabled={isAtStart()}
          />
        </div>
        <div className="carousel__container w-full relative overflow-hidden">
          <div className="carousel__items-container absolute flex flex-row transition-left ease-in-out duration-500">
            {props.items.map((item: CarouselItem, index: number) => {
              return (
                <div className="carousel-item" key={item.title + index}>
                  {renderTemplate(props.template, item, index)}
                </div>
              )
            })}
          </div>
        </div>
        <div
          style={styles.pager}
          className="carousel__pager--right absolute right-3 z-20 hidden sm:block"
        >
          <PagerButton
            onClick={() => (!isAtEnd() ? next() : null)}
            type="NEXT"
            disabled={isAtEnd()}
          />
        </div>
      </div>
      <div className="carousel__page--dots flex flex-row gap-2 mt-16 justify-center">
        <PagerLines
          onClick={(_, index: number) => (!isActive(index, currentPage) ? move(index) : null)}
          theme={props.theme}
          pageNumber={pageNumber}
          currentPage={currentPage}
        />
      </div>
    </div>
  )
}

export default Wide
