import { TouchEventHandler, useState } from 'react'

export interface SwipeHookResult {
  handleTouchStart: (e: any) => void
  handleTouchMove: (
    e: any,
    callbackPrev: () => void,
    callbackNext: () => void
  ) => TouchEventHandler<HTMLDivElement> | undefined
}

const useSwipe = () => {
  const [touchPosition, setTouchPosition] = useState(null)

  const handleTouchStart = (e: any) => {
    const touchDown = e.touches[0].clientX
    setTouchPosition(touchDown)
  }

  const handleTouchMove = (e: any, callbackPrev: () => void, callbackNext: () => void) => {
    const touchDown = touchPosition
    if (touchDown === null) {
      return
    }

    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch
    if (diff > 5) {
      callbackNext()
    }
    if (diff < -5) {
      callbackPrev()
    }

    setTouchPosition(null)
  }

  return {
    handleTouchStart,
    handleTouchMove
  } as SwipeHookResult
}

export default useSwipe
