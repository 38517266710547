import { useRef, useState } from 'react'
import ButtonModern from '@/components/Button/ButtonModern'
import { Spinner } from '@/components/Spinner'
import { fetchAPI } from '@/utils/api'
import { isEmail } from '@/utils/validator'
import { subscriptionMessages } from '@/consts/subscriptionMessages'

export const NewsLetter = () => {
  const inputRef = useRef(null)

  const [loading, setLoading] = useState(false)

  type SubscriptionStatus = 'success' | 'already' | 'error' | 'missing' | 'invalid' | null
  const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionStatus | null>(null)

  const validateEmail = (value: string) => {
    if (!value) {
      setSubscriptionStatus('missing')
      return false
    }
    if (!isEmail(value)) {
      setSubscriptionStatus('invalid')
      return false
    }
    return true
  }

  const subcsribe = async (email: any) => {
    setSubscriptionStatus(null)
    if (loading || !validateEmail(email)) {
      return
    }
    setLoading(true)
    try {
      //Check if already subscribed
      const mailchimpRes = await fetchAPI('GET_SUBSCRIPTION', { email })
      const { subscribed } = await mailchimpRes.json()
      if (subscribed) {
        setSubscriptionStatus('already')
        setLoading(false)
        return
      }

      //Add subscription
      const response = await fetchAPI('ADD_SUBSCRIPTION', undefined, { email: email })
      if (response.status === 200) {
        setSubscriptionStatus('success')
      } else {
        setSubscriptionStatus('error')
      }
      setLoading(false)
    } catch (e) {
      setSubscriptionStatus('error')
      setLoading(false)
    }
  }

  const onClick = () => {
    if (!loading && inputRef.current) {
      subcsribe((inputRef.current as any).value)
    }
  }

  const onPressEnter = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter') onClick()
  }

  const getSubscriptionStatusText = () => {
    if (!subscriptionStatus) {
      return ''
    } else {
      return subscriptionMessages[subscriptionStatus]
    }
  }

  const getSubscriptionStatusTextColor = () => {
    switch (subscriptionStatus) {
      case 'success':
        return 'text-kiwi'
      case 'already':
        return 'text-kiwi'
      case 'error':
        return 'text-red'
      case 'missing':
        return 'text-red'
      case 'invalid':
        return 'text-red'
      default:
        break
    }
  }

  return (
    <>
      <div className="newsLetter text-center mb-10 md:mb-16">
        <p className="font-gilroy font-extrabold xt-center mb-3 text-4xl lg:text-5xl mx-5">
          Iratkozz fel a hírlevelünkre!
        </p>
        <p className="font-roboto text-lg font-bold max-w-2xl ml-auto mr-auto">
          Kövesd nyomon hírlevelünkben a Popkulturális blogbejegyzéseit és a legfrissebb
          feltöltéseket!
        </p>
        <div className="pl-5 pr-5 ">
          <input
            ref={inputRef}
            onKeyPress={onPressEnter}
            type="text"
            className="focus:outline-none p-3 pl-5 pr-5 sm:mr-3 text-base font-medium leading-6 text-white w-full sm:w-[445px] md:w-[575px] lg:w-[625px] bg-black border rounded"
            placeholder="Írd be az e-mail címedet"
            disabled={loading}
          />
          <ButtonModern className="w-full sm:w-auto " onClick={onClick}>
            {loading && (
              <Spinner className="w-5 h-5 mb-[-5px] mr-3 inline-block -translate-y-1 -translate-x-1" />
            )}
            <span className="align-middle">Feliratkozás</span>
          </ButtonModern>
        </div>
        {subscriptionStatus && (
          <div className="mt-2">
            <span className={`text-sm ${getSubscriptionStatusTextColor()}`}>
              {getSubscriptionStatusText()}
            </span>
          </div>
        )}
      </div>
    </>
  )
}

export default NewsLetter
