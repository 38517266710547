import { getEntityType, getEntityUrl } from '@/utils'
import { getTileImageProps } from '@/utils/image'
import { CarouselItem } from '@/components/Carousel/types'
import SingleTileImage from '@/components/Tiles/SingleTileImage'
import { gradients } from '@/components/Tiles/consts'
import imageClasses from '@/components/Tiles/singleTileImage.module.css'
import homepage from '../../../styles/homepage.module.css'
import { Link } from '@/components/Link/Link'

const RectangleImageNameRange = (props: CarouselItem) => {
  if (!props.entity) {
    return null
  }

  const bg = `bg-gradient-to-tr ${gradients[getEntityType(props.entity)] || gradients.default}`
  const classes = {
    ...imageClasses,
    watermarkWrapper: homepage['tile-watermark-wrapper'] + ' ' + bg,
    watermarkImage: homepage['tile-watermark-image']
  }

  return (
    <div className="carousel-item--rectangle-image-name-range flex flex-col relative items-center cursor-pointer w-full">
      <Link href={props.entity ? getEntityUrl(props.entity) : '#'} style={{ width: '100%' }}>
        <div className="carousel-item__container box-border w-full sm:p-6">
          <div
            className={`carousel-item__image mb-3 relative w-full`}
            style={{ paddingBottom: '142.4242424%' }}
          >
            <div
              className={`${homepage['tile-hover']} ${homepage['tile-overlay']} absolute w-full h-full`}
            >
              <SingleTileImage {...getTileImageProps('portrait', props.entity)} classes={classes} />
            </div>
          </div>
          <h3 className="carousel-item__name font-roboto font-extrabold text-xl lg:text-3xl text-white">
            {props.title}
          </h3>
          {props.subtitle && props.subtitle !== '' && (
            <p className="carousel-item__range font-roboto font-extrabold leading-6 text-xl text-white opacity-50">
              {props.subtitle}
            </p>
          )}
        </div>
      </Link>
    </div>
  )
}

export default RectangleImageNameRange
