export interface AvatarProps {
  src: string
  alt: string
  className: string
}

const Avatar = (props: AvatarProps) => {
  return (
    <span
      className={`inline-block overflow-hidden rounded-full border border-white ${props.className}`}
    >
      <img className="w-full h-full" src={props.src} alt={props.alt} />
    </span>
  )
}

export default Avatar
