import Image from '../Image'

export interface SingleTileImageProps {
  tileType: string
  idealImageUrl: string
  squareImageUrl: string
  mtvaImageUrl: string | null
  mtvaImagePosition: string
  mtvaImageHash: string
  fortepanImageSource: string | null
  defaultImageUrl: string | null | undefined
  isPublication: boolean
  customImageSource?: string
  classes: { [key: string]: string }
}

export default function SingleTileImage({
  idealImageUrl,
  squareImageUrl,
  mtvaImageUrl,
  mtvaImagePosition,
  defaultImageUrl,
  isPublication,
  classes
}: SingleTileImageProps) {
  if (idealImageUrl) {
    return <Image src={idealImageUrl} className={classes.image} />
  }

  if (mtvaImageUrl) {
    return (
      <Image
        src={mtvaImageUrl}
        className={classes.image}
        position={mtvaImagePosition}
        useSrcLoader
      />
    )
  }

  if (defaultImageUrl) {
    return <Image src={defaultImageUrl} className={classes.defaultImage} />
  }

  if (squareImageUrl && isPublication) {
    return (
      <div>
        <Image
          src={squareImageUrl}
          className={classes.containedImage}
          contain
          position="center center"
        />
        <Image src={squareImageUrl} className={classes.backgroundImage} />
      </div>
    )
  }

  return (
    <div className={classes.watermarkWrapper || 'opacity-25 relative h-full'}>
      <Image
        src="/images/mpe.png"
        className={
          classes.defaultImage + (classes.watermarkImage ? ' ' + classes.watermarkImage : '')
        }
      />
    </div>
  )
}
