import Avatar from '@/components/Avatar'
import { Link } from '../Link/Link'

export interface BannerMpeArchiveProps {
  title: string
  text: string
  quotation: string
  artist: string
  referral: string
  referral_url?: string
  url: string
}

const BannerMpeArchive = (props: { content: BannerMpeArchiveProps }) => {
  return (
    <div className="banner-mpe-archive mt-36">
      <div className="banner-mpe-archive__container container-padded">
        <div className="banner-mpe-archive__title">
          <h2 className="font-gilroy text-5xl font-extrabold max-w-2/3 lg:max-w-1/2">
            {props.content.title}
          </h2>
        </div>
        <div className="banner-mpe-archive__body">
          <div className="grid gird-cols-1 md:grid-cols-2">
            <div className="archive mt-10 pr-0 lg:pr-10 xl:pr-20 2xl:pr-48">
              <p className="font-roboto text-xl font-bold mb-3 md:mb-28">{props.content.text}</p>
            </div>
            <div className="quotation bg-ditto-mark bg-no-repeat bg-ditto-mark-position pl-12 pt-28 md:pt-10">
              <div>
                <p className="font-roboto text-2xl font-bold mb-6">{props.content.quotation}</p>
                <div className="artist flex items-center mb-6">
                  <span className="artist__avatar mr-4">
                    <Avatar
                      className="w-14 h-14"
                      src="/images/javorszky-bela-avatar.jpg"
                      alt="Jávorszky Béla Szilárd"
                    />
                  </span>
                  <Link href={props.content.url || '#'}>
                    <span className="artist__name font-roboto text-3xl font-bold italic">
                      {props.content.artist}
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="grid gird-cols-1 md:grid-cols-2">
            <div className="banner-mpe-archive__logos mt-14 md:mt-0 pr-0 lg:pr-10 xl:pr-20 2xl:pr-48 order-2 md:order-1">
              <div className="pr-0 xl:pr-16 2xl:pr-28">
                <div className="grid grid-cols-2 mb-10">
                  <div className="w-28 h-10 justify-self-start self-center">
                    <Link href="https://petofiugynokseg.hu" target="_blank">
                      <img src="/images/pku-logo-2024.png" alt="Petőfi Ügynökség logo" />
                    </Link>
                  </div>
                  <div className="w-20 h-14 justify-self-end self-center">
                    <Link href="https://a38.hu" target="_blank">
                      <img src="/images/A38_logo_white.png" alt="A38 logo" />
                    </Link>
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="w-52 h-9 justify-self-start self-center">
                    <Link href="https://fortepan.hu/" target="_blank">
                      <img src="/images/fortepan-logo.png" alt="Fortepan logo" />
                    </Link>
                  </div>
                  <div className="w-42 h-9 justify-self-end self-center">
                    <Link href="https://zeneszoveg.hu/" target="_blank">
                      <img src="/images/zeneszoveg-logo.png" alt="Zeneszöveg.hu logo" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="referral md:pl-12 order-1 md:order-2">
              <div className="grid gird-cols-1">
                <div>
                  {props.content.referral_url && props.content.referral_url != '' ? (
                    <Link href={props.content.referral_url}>
                      <p className="font-roboto text-xl leading-8 font-normal">
                        {props.content.referral}
                      </p>
                    </Link>
                  ) : (
                    <p className="font-roboto text-xl leading-8 font-normal">
                      {props.content.referral}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BannerMpeArchive
