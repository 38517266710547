import HighlightedWithShortList from '@/components/ArticleList/Templates/HighlightedWithShortList'
import { ArticleListProps } from '@/components/ArticleList/types'
import { Link } from '../Link/Link'
import ShortListOnly from './Templates/ShortListOnly'

const ArticleList = (props: ArticleListProps) => {
  const renderTemplates = () => {
    if (props.template === 'HIGHLIGHTED_WITH_SHORT_LIST') {
      return <HighlightedWithShortList {...props} />
    }
    if (props.template === 'SHORT_LIST_ONLY') {
      return <ShortListOnly {...props} />
    }
  }

  return (
    <div className="article-list mt-16">
      <div className="container-padded">
        {renderTemplates()}
        {!props.hideNextButton && (
          <div className="text-right mt-12">
            <Link href="https://blog.popkulturalis.hu" target="_blank" title="Pokpulturális blog">
              <span className="underline cursor-pointer font-medium">További blogbejegyzések</span>
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default ArticleList
