import { getEntityType, getEntityUrl } from '@/utils'
import { getTileImageProps } from '@/utils/image'
import { CarouselItem } from '@/components/Carousel/types'
import SingleTileImage from '@/components/Tiles/SingleTileImage'
import { gradients } from '@/components/Tiles/consts'
import imageClasses from '@/components/Tiles/singleTileImage.module.css'
import homepage from '../../../styles/homepage.module.css'
import { Link } from '@/components/Link/Link'

const SquareImageNameTitle = (props: CarouselItem) => {
  if (!props.entity) {
    return null
  }

  const bg = `bg-gradient-to-tr ${gradients[getEntityType(props.entity)] || gradients.default}`
  const classes = {
    ...imageClasses,
    watermarkWrapper: `${homepage['tile-watermark-wrapper']} ${bg}`,
    watermarkImage: homepage['tile-watermark-image']
  }

  return (
    <div className="carousel-item__container">
      <Link href={props.entity ? getEntityUrl(props.entity) : '#'} style={{ width: '100%' }}>
        <div className="carousel-item--square-image-name-title flex flex-col relative items-center cursor-pointer">
          <div className="box-border w-full sm:p-6">
            <div
              className={`carousel-item__image mb-3 relative w-full`}
              style={{ paddingBottom: '100%' }}
            >
              <div
                className={`${homepage['tile-hover']} ${homepage['tile-overlay']} absolute w-full h-full`}
              >
                <SingleTileImage {...getTileImageProps('square', props.entity)} classes={classes} />
              </div>
            </div>
            {props.subtitle && props.subtitle !== '' && (
              <p className="carousel-item__name font-roboto font-extrabold leading-6 text-base lg:text-xl text-white opacity-50">
                {props.subtitle}
              </p>
            )}
            <h3 className="carousel-item__title font-roboto font-extrabold text-xl lg:text-3xl text-white truncate">
              {props.title}
            </h3>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default SquareImageNameTitle
