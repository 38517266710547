import StoryWithCarousel from '@/components/Collection/Templates/StoryWithCarousel'
import { CollectionProps, CollectionTemplateType } from '@/components/Collection/types'
import Tiles from '@/components/Collection/Templates/Tiles'
import StoryCarousel from './Templates/StoryCarousel'

const Collection = (props: CollectionProps) => {
  if (props.items.length === 0) {
    return null
  }

  return (
    <div className="collection">
      {
        {
          STORY_WITH_CAROUSEL: <StoryWithCarousel {...props} />,
          TILES: <Tiles {...props} />,
          STORY_CAROUSEL: <StoryCarousel {...props} />
        }[props.template as CollectionTemplateType]
      }
    </div>
  )
}

export default Collection
