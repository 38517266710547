import { BaseSectionItem } from '@/types/homeContent.model'
import Video from '@/components/Video/Video'

export interface VideoLyricsProps {
  title: string
  items: BaseSectionItem[]
}

const VideoLyrics = (props: VideoLyricsProps) => {
  return (
    <div className="video-lyrics py-16 flex justify-center">
      <div className=" w-full lg:w-3/4">
        <div className="container-padded">
          <h2 className="font-gilroy font-extrabold text-4xl sm:text-5xl text-center max-w-2xl ml-auto mr-auto mb-10">
            {props.title}
          </h2>
          <div className="grid gird-cols-1 lg:grid-cols-2 gap-2">
            <div className="quotation bg-ditto-mark bg-no-repeat bg-ditto-mark-position pl-12 pt-28 md:pt-10">
              <div>
                <p className="font-roboto text-2xl font-bold mb-6">{props.items[0].lead}</p>
              </div>
            </div>
            <div className="video-lyrics__video flex flex-col relative items-center min-w-full">
              <Video {...props.items[0]} isVisible={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoLyrics
