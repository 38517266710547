import { getEntityType, getEntityUrl } from '@/utils'
import { getTileImageProps } from '@/utils/image'
import { CollectionItem } from '@/components/Collection/types'
import SingleTileImage from '@/components/Tiles/SingleTileImage'
import imageClasses from '@/components/Tiles/singleTileImage.module.css'
import { gradients } from '@/components/Tiles/consts'
import homepage from '../../../../styles/homepage.module.css'
import { Link } from '@/components/Link/Link'

const Tile = (props: CollectionItem) => {
  if (!props.entity) {
    return null
  }

  const bg = `bg-gradient-to-tr ${gradients[getEntityType(props.entity)] || gradients.default}`
  const classes = {
    ...imageClasses,
    watermarkWrapper: homepage['tile-watermark-wrapper'] + ' ' + bg,
    watermarkImage: homepage['tile-watermark-image']
  }

  return (
    <div className={`collection-tile cursor-pointer ${homepage['tile-hover']}`}>
      <Link href={props.entity ? getEntityUrl(props.entity) : '#'}>
        <div className={`collection-tile__container relative`} style={{ paddingBottom: '100%' }}>
          <div className={`${homepage['tile-overlay']} absolute w-full h-full`}>
            <SingleTileImage {...getTileImageProps('square', props.entity)} classes={classes} />
          </div>
          <div className="collection-tile__label-container absolute top-0 left-0 w-full h-full">
            <div className="p-6 h-full flex justify-start items-end">
              <div>
                <div>
                  <h3 className="collection-tile__title font-roboto font-extrabold text-xl lg:text-3xl inline-block bg-purple p-3">
                    {props.title}
                  </h3>
                </div>
                <div>
                  {props.subtitle && props.subtitle !== '' && (
                    <h4 className="collection-tile__instrument font-roboto font-normal text-base lg:text-lg leading-5 inline-block bg-lightSkyBlue p-2">
                      {props.subtitle}
                    </h4>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default Tile
