import React, { MouseEvent } from 'react'
import { CarouselThemeType } from '@/components/Carousel/types'

export interface PagerLinesProps {
  theme?: CarouselThemeType
  pageNumber: number
  currentPage: number
  onClick?: (event: MouseEvent<HTMLSpanElement>, index: number) => void
}

const PagerLines = ({ theme = 'LIGHT', pageNumber, currentPage, onClick }: PagerLinesProps) => {
  const getColorClass = (index: number, currentPage: number): string => {
    return theme === 'LIGHT'
      ? index === currentPage
        ? 'bg-indigo'
        : 'bg-darkGray'
      : index === currentPage
      ? 'bg-white'
      : 'bg-white opacity-50'
  }

  return (
    <>
      {[...Array(pageNumber)].map((_, index: number) => {
        const bgClass = getColorClass(index, currentPage)

        return (
          <span
            key={index}
            onClick={(_) => {
              onClick ? onClick(_, index) : null
            }}
            className={`inline-block mx-2 w-24 h-1 ${bgClass} ${
              index === currentPage ? 'cursor-default' : 'cursor-pointer'
            }`}
          ></span>
        )
      })}
    </>
  )
}

export default PagerLines
