import { Article } from '@/components/ArticleList/types'
import { Link } from '@/components/Link/Link'

export interface ListItemProps {
  article: Article
}

const ListItem = (props: ListItemProps) => {
  return (
    <Link href={props.article.url || '#'} title={props.article.title} target="_blank">
      <div className="article-list__list-item cursor-pointer">
        <div className="mb-4 aspect-w-4 aspect-h-3">
          <img
            className="object-cover"
            src={props.article.imageUrl}
            alt={props.article.imageAlt || props.article.title}
          />
        </div>
        <div>
          <h2 className="font-roboto font-extrabold text-xl md:text-2xl mb-2 hover:underline">
            {props.article.title}
          </h2>
          <p className="mb-12">
            <span className="font-roboto font-normal text-base text-lightGray">
              {props.article.date}
            </span>
          </p>
        </div>
      </div>
    </Link>
  )
}

export default ListItem
