import Carousel from '@/components/Collection/Templates/StoryCarousel/Carousel'
import { CollectionProps } from '@/components/Collection/types'

const StoryCarousel = (props: CollectionProps) => {
  return (
    <div className="story-with-carousel-collection py-28 bg-gradient-to-l from-lightSkyBlue to-purple">
      <div className="container-padded">
        <div className="collection__carousel">
          <Carousel items={props.items} />
        </div>
      </div>
    </div>
  )
}

export default StoryCarousel
