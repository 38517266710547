export interface NumberItem {
  number: number
  prefix: string
  suffix: string
}

export interface NumbersProps {
  items: NumberItem[]
}

const Numbers = (props: NumbersProps) => {
  return (
    <div className="numbers grid grid-col-1 md:grid-cols-3 sm:justify-center px-5 pt-32 pb-12 sm:py-32 md:py-52 lg:py-72 max-w-screen-lg w-100 ml-auto mr-auto">
      {props.items.map((item: NumberItem) => (
        <div key={item.number} className="number max-w-xs pb-10 md:pb-0">
          <div className="flex">
            <div>
              <span className="number__line border border-white inline-block w-px h-36 mt-4"></span>
            </div>
            <div className="pl-5 pr-10">
              <p className="prefix font-roboto font-normal text-xl">{item.prefix}</p>
              <p className="the-number font-roboto font-extrabold text-6xl my-4">
                {item.number}
                <span className="font-gilroy font-light text-7xl leading-4 text-skyBlue ml-5">
                  +
                </span>
              </p>
              <p className="suffix font-roboto font-normal text-xl">{item.suffix}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Numbers
