interface Endpoint {
  url: string
  method: 'POST' | 'GET' | 'DELETE' | 'PATCH' | 'PUT'
  auth?: boolean
}

interface Headers {
  [key: string]: string
}

const ENDPOINTS: { [key: string]: Endpoint } = {
  GET_SELF: {
    url: '/api/user',
    method: 'GET',
    auth: true
  },
  UPDATE_SELF: {
    url: '/api/user',
    method: 'POST',
    auth: true
  },
  GET_SUBSCRIPTION: {
    url: '/api/mailchimp',
    method: 'GET'
  },
  ADD_SUBSCRIPTION: {
    url: '/api/mailchimp',
    method: 'POST'
  },
  REMOVE_SUBSCRIPTION: {
    url: '/api/mailchimp',
    method: 'DELETE'
  }
}

export async function fetchAPI(
  endpointName: keyof typeof ENDPOINTS,
  params?: { [key: string]: string },
  payload?: string | object
) {
  const endpoint = ENDPOINTS[endpointName]
  const headers: Headers = {}
  if (endpoint.auth) {
    const token = localStorage.getItem('user_token')
    if (!token) throw 'No auth token presented'
    headers.authorization = `Bearer ${token}`
  }

  //Yes, DELETE can have payload sometimes eg. REMOVE_SUBSCRIPTION
  if (['POST', 'PATCH', 'PUT', 'DELETE'].includes(endpoint.method))
    headers['content-type'] = 'application/json'

  return await fetch(`${endpoint.url}${params ? '?' + new URLSearchParams(params) : ''}`, {
    method: endpoint.method,
    headers,
    ...(payload && { body: JSON.stringify(payload) })
  })
}
