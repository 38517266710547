export const tilePattern: string[] = [
  'wide',
  'portrait',
  'square',
  'square',
  'portrait',
  'square',
  'square',
  'wide',
  'portrait',
  'wide',
  'square',
  'square',
  'portrait',
  'square',
  'square',
  'wide'
]

// This matrix contains pattern indices which fixes last row's arrangement
// to conform with bottom line.
// Index of rows in main array represents how many tiles are in last row.
// If an inside array item is null that means the tile should have
// it's originally calculated pattern index.
// The first 4 values represents the penult row's last 4 pattern indices.
export const lastTilePattern: any = [
  /* 0 item */ [],
  /* 1 item */ [null, null, null, 'square', 'square'],
  /* 2 item */ [null, 'wide', 'wide', 'wide', 'wide', 'wide'],
  /* 3 item */ [null, null, null, null, 'wide', 'wide', 'wide'],
  /* 4 item */ [null, null, null, null, 'square', 'wide', 'square', 'wide'],
  /* 5 item */ [null, null, null, null, 'square', 'wide', 'square', 'square', 'square'],
  /* 6 item */ [null, null, null, null, 'wide', 'portrait', 'wide', 'portrait', 'wide', 'wide'],
  /* 7 item */ [null, null, null, null, null, null, null, null, null, 'wide', 'wide']
]

export const gradients: { [key: string]: string } = {
  Album: 'from-beige to-indigo',
  Audio: 'from-tomato to-indigo',
  Award: 'from-skyBlue to-beige',
  Band: 'from-indigo to-skyBlue',
  Event: 'from-tomato to-skyBlue',
  EventSeries: 'from-kiwi to-paleYellow',
  Image: 'from-indigo to-peach',
  Institution: 'from-tomato to-paleYellow',
  MusicalPeriod: 'from-kiwi to-skyBlue',
  Person: 'from-indigo to-magenta',
  Publication: 'from-seaBlue to-mint',
  PublicationItem: 'from-mint to-seaBlue',
  Song: 'from-magenta to-skyBlue',
  Tag: 'from-magenta to-mint', // missing
  Venue: 'from-indigo to-kiwi',
  Video: 'from-magenta to-peach',
  static: 'from-violet to-yellow',
  default: 'from-seaBlue to-skyBlue'
}
