import { useEffect } from 'react'

export const TikTokEmbed = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://www.tiktok.com/embed.js'
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <blockquote
      className="tiktok-embed"
      cite="https://www.tiktok.com/@popkulturalis.hu"
      data-unique-id="popkulturalis.hu"
      data-embed-from="embed_page"
      data-embed-type="creator"
      style={{ maxWidth: '780px', minWidth: '288px' }}
    >
      <section>
        <a target="_blank" href="https://www.tiktok.com/@popkulturalis.hu?refer=creator_embed">
          @popkulturalis.hu
        </a>
      </section>
    </blockquote>
  )
}
