import { Link } from '../Link/Link'

const BannerFollowIndex = () => {
  return (
    <div className="banner-follow-index bg-gradient-to-l from-lightSkyBlue to-purple py-28">
      <div className="container-padded">
        <div className="grid grid-cols-1 md:grid-cols-2 items-center">
          <div className="sm:pr-36 md:pr-10">
            <div className="max-w-[590px]">
              <h2 className="font-gilroy font-extrabold text-4xl sm:text-5xl mb-10">
                Kövesd blogunkat az index.hu-n!
              </h2>
              <p className="font-roboto font-bold text-xl">
                A magyar könnyűzene világát feldolgozó legfrissebb cikkekért kövesd blogunkat az
                Indexen is!
              </p>
            </div>
          </div>
          <div className="text-center md:text-right pt-14 md:pt-0">
            <Link
              href="https://index.hu/kultur/popkult"
              target="_blank"
              title="Popkultúrális"
              className="cursor-pointer"
            >
              <img
                className="inline-block w-[530px] h-[150px]"
                src="/images/index-logo.svg"
                alt="index.hu"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BannerFollowIndex
