import { LegacyRef, MouseEventHandler, useEffect, useRef, useState } from 'react'

export interface CarouselHookResult {
  sliderRef: LegacyRef<HTMLDivElement>
  pageNumber: number
  currentPage: number
  setBreakpoints: (payload: Breakpoints) => void
  move: (page: number) => MouseEventHandler<HTMLSpanElement>
  prev: () => void
  next: () => void
  isAtStart: () => boolean
  isAtEnd: () => boolean
  isActive: (index: number, active: number) => boolean
}

export type BreakpointKey = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
export type Breakpoints = {
  [key in BreakpointKey]: number
}

const useCarousel = () => {
  const sliderRef = useRef<HTMLElement>()
  let slider = sliderRef?.current
  let container: HTMLDivElement
  let itemsContainer: HTMLDivElement
  let items: HTMLDivElement[]
  const [windowWidth, setWindowWidth] = useState(0)
  const [breakpoints, setBreakpoints] = useState({
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1,
    xl: 1,
    '2xl': 1
  } as Breakpoints)
  const [pageNumber, setPageNumber] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)

  useEffect(() => {
    initCarousel()
  }, [sliderRef.current, windowWidth])

  useEffect(() => {
    let resizeTimer: NodeJS.Timeout
    window.addEventListener('resize', (e: UIEvent) => {
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(function () {
        setWindowWidth((e.target as Window).innerWidth)
      }, 250)
    })
    return () => {
      window.removeEventListener('resize', initCarousel)
      clearTimeout(resizeTimer)
    }
  }, [])

  useEffect(() => {
    if (!slider) {
      return
    }
    if (!itemsContainer) {
      setItemsContainer()
    }
    if (!container) {
      setContainer()
    }
    const position: number = currentPage * container.clientWidth
    itemsContainer.style.left = `${position > 0 ? position * -1 : position}px`
  }, [currentPage])

  const initCarousel = () => {
    slider = sliderRef?.current
    if (!slider) {
      return
    }

    setContainer()
    setItemsContainer()
    setItems()

    setCurrentPage(0)
    setPageNumber(Math.ceil(items.length / getColumnNumber() || 0))
    initItems()
    initContainer()
  }

  const getColumnNumber = (): number => {
    const currentBreakpoint = getCurrentBreakpoint()
    return currentBreakpoint && breakpoints[currentBreakpoint] ? breakpoints[currentBreakpoint] : 1
  }

  const getCurrentBreakpoint = (): BreakpointKey | undefined => {
    setWindowWidth((window as Window).innerWidth)
    if (windowWidth >= 0 && windowWidth < 640) {
      return 'xs'
    } else if (windowWidth >= 640 && windowWidth < 768) {
      return 'sm'
    } else if (windowWidth >= 768 && windowWidth < 1024) {
      return 'md'
    } else if (windowWidth >= 1024 && windowWidth < 1280) {
      return 'lg'
    } else if (windowWidth >= 1280 && windowWidth < 1536) {
      return 'xl'
    } else if (windowWidth >= 1536) {
      return '2xl'
    }
    return
  }

  const setContainer = () => {
    if (!slider) return
    container = slider.querySelector('.carousel__container') as HTMLDivElement
  }

  const setItemsContainer = () => {
    if (!slider) return
    itemsContainer = slider.querySelector('.carousel__items-container') as HTMLDivElement
  }

  const setItems = () => {
    if (!slider) return
    items = Array.from(slider.querySelectorAll('.carousel-item')) as HTMLDivElement[]
  }

  const initContainer = () => {
    if (items.length === 0) {
      return
    }
    const getLargestItemHeight = () => {
      items.sort((a: HTMLDivElement, b: HTMLDivElement) =>
        a.clientHeight < b.clientHeight ? -1 : 1
      )
      return items[items.length - 1].clientHeight
    }
    if (container) {
      container.style.height = `${getLargestItemHeight()}px`
    }
  }

  const initItems = () => {
    items.map((item: HTMLDivElement) => {
      item.style.width = `${(container.clientWidth || 0) / getColumnNumber()}px`
    })
  }

  const move = (page: number): void => {
    setCurrentPage(page)
  }

  const prev = (): void => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1)
    }
  }

  const next = (): void => {
    if (!isAtEnd()) {
      setCurrentPage(currentPage + 1)
    }
  }

  const isActive = (index: number, active: number): boolean => {
    return index === active
  }

  const isAtStart = (): boolean => {
    return currentPage === 0
  }

  const isAtEnd = (): boolean => {
    return currentPage === pageNumber - 1
  }

  return {
    sliderRef,
    pageNumber,
    currentPage,
    setBreakpoints,
    move,
    prev,
    next,
    isActive,
    isAtStart,
    isAtEnd
  } as CarouselHookResult
}

export default useCarousel
