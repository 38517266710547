import { useEffect } from 'react'
import { GetServerSidePropsContext } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { getHomePageContent } from '@/fetchers'
import Layout from '@/components/Layout'
import BlogHeader from '@/components/BlogHeader'
import ArticleList from '@/components/ArticleList'
import Search from '@/components/Search'
import BannerMpeArchive from '@/components/BannerMpeArchive'
import Numbers from '@/components/Numbers'
import Carousel from '@/components/Carousel/Carousel'
import { CarouselProps } from '@/components/Carousel/types'
import Collection from '@/components/Collection'
import { CollectionProps } from '@/components/Collection/types'
import BannerFollowIndex from '@/components/BannerFollowIndex'
import { HomeContentModel } from '@/types/homeContent.model'
import VideoBox from '@/components/HomePage/VideoBox/VideoBox'
import getConfig from 'next/config'
import { bannerFestivalCollection } from '@/consts/bannerFestivalCollection'
import BannerFestival from '@/components/BannerFestival'
import { TikTokEmbed } from '@/components/TikTokEmbed'
import VideoLyrics, { VideoLyricsProps } from '@/components/VideoLyrics'
import NewsLetter from '@/components/NewsLetter'

const collectionsOrder = ['NEWEST_ARTIST_COLLECTION', 'GENRE_COLLECTION', 'ERA_COLLECTION']

export default function Index(props: any) {
  const router = useRouter()
  useEffect(() => {
    if (!props.content || Object.keys(props.content).length === 0) {
      router.push('/eloado/1')
    }
  })

  const title = 'Popkulturális'
  const content: HomeContentModel = props.content || {}
  const fbAppId = getConfig().publicRuntimeConfig?.fbAppId || null

  // order collections
  const orderedCollections: CollectionProps[] = []
  Object.entries(content.collections || {}).forEach(([key, collection]) => {
    const collectionProp = collection as CollectionProps
    const inOrder = collectionsOrder.includes(key)

    if (!orderedCollections.length || !inOrder) {
      orderedCollections.push(collectionProp)
    } else if (inOrder) {
      const newIndex = collectionsOrder.indexOf(key)
      orderedCollections.splice(newIndex, 0, collectionProp)
    }
  })

  return (
    <>
      <Head>
        <title>{title}</title>
        {fbAppId && (
          <>
            <meta property="og:url" content={content.meta?.url || '/'} />
            <meta property="og:type" content={content.meta?.type || 'website'} />
            <meta
              property="og:title"
              content={content.meta?.title || 'Magyar Popkulturalis Értéktár'}
            />
            <meta property="og:description" content={content.meta?.description || ''} />
            <meta property="og:image" content={content.meta?.image || ''} />
            <meta property="fb:app_id" content={fbAppId} />
          </>
        )}
      </Head>
      <div className="min-w-[320px]">
        <Layout>
          <div className="mt-16 xl:mt-24">
            <BlogHeader />
            {content.articleList &&
              content.articleList.LATEST_ARTICLES &&
              content.articleList.LATEST_ARTICLES.articles.length >= 4 && (
                <ArticleList {...content.articleList.LATEST_ARTICLES} />
              )}
            <div className="bg-ellipse-left bg-no-repeat bg-left-top">
              <div className="bg-ellipse-right bg-no-repeat bg-right-bottom">
                <NewsLetter />
                <Search />
                {content.bannerMpeArchive && (
                  <BannerMpeArchive content={content.bannerMpeArchive} />
                )}
                {content.numbers && content.numbers.items && (
                  <Numbers items={content.numbers.items} />
                )}
                {bannerFestivalCollection && <BannerFestival content={bannerFestivalCollection} />}
              </div>
            </div>
            {content.carousels && content.carousels.LATEST_UPLOADS && (
              <>
                <div className="mb-[-244px] sm:mb-[-268px] lg:mb-[-316px] xl:mb-[-315px] 2xl:mb-[-280px]">
                  <Carousel {...(content.carousels.LATEST_UPLOADS as CarouselProps)} />
                </div>
                <div className="bg-gradient-to-l from-lightSkyBlue to-purple w-full h-[550px] sm:h-[690px]"></div>
              </>
            )}
            {content.carousels && content.carousels.CONCERTS && (
              <div className="mt-[-305px] sm:mt-[-322px] md:mt-[-357px] lg:mt-[-360px] xl:mt-[-413px] 2xl:mt-[-466px]">
                <Carousel {...(content.carousels.CONCERTS as CarouselProps)} />
              </div>
            )}
            {content.carousels && content.carousels.MOST_VIEWED && (
              <Carousel {...(content.carousels.MOST_VIEWED as CarouselProps)} />
            )}
            {orderedCollections.map((collectionProps, index) => {
              return <Collection {...collectionProps} key={index} />
            })}
            <TikTokEmbed />
            {content.carousels && content.carousels.GENERATION && (
              <Carousel {...(content.carousels.GENERATION as CarouselProps)} />
            )}
            {content.carousels &&
              content.carousels.VIDEO_LYRICS &&
              content.carousels.VIDEO_LYRICS.items[0] && (
                <VideoLyrics {...(content.carousels.VIDEO_LYRICS as VideoLyricsProps)} />
              )}
            {content.randomVideoPlayer && <VideoBox {...content.randomVideoPlayer} />}
            <BannerFollowIndex />
          </div>
        </Layout>
      </div>
    </>
  )
}

export async function getServerSideProps({ res }: GetServerSidePropsContext) {
  res.setHeader('Cache-Control', 'no-cache, no-store, max-age=0, must-revalidate')

  const contentEntity: any = await getHomePageContent()

  return {
    props: { content: contentEntity?.data || null }
  }
}
