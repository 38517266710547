import ButtonModern from '../Button/ButtonModern'

export interface BannerFestivalProps {
  title: string
  text: string
  button_text: string
  button_url: string
  image: string
  image_alt: string
}

const BannerFestival = (props: { content: BannerFestivalProps }) => {
  return (
    <div className="banner-mpe-archive mt-36">
      <div className="banner-mpe-archive__container container-padded">
        <div className="banner-mpe-archive__title">
          <h2 className="font-gilroy text-5xl font-extrabold">{props.content.title}</h2>
        </div>
        <div className="banner-mpe-archive__body">
          <div className="grid gird-cols-1 md:grid-cols-2">
            <div className=" mt-10 pr-0 lg:pr-10 xl:pr-20 2xl:pr-48">
              <img
                className=" object-cover w-full h-full"
                src={props.content.image}
                alt={props.content.image_alt}
              />
            </div>
            <div className="md:pl-12 pt-10 md:pt-10">
              <div className="artist flex flex-col items-center mb-6">
                <p className="text-justify font-roboto text-2xl font-bold mb-6">
                  {props.content.text}
                </p>
                <ButtonModern
                  className="w-full sm:w-full"
                  onClick={() => window.open(props.content.button_url)}
                >
                  {props.content.button_text}
                </ButtonModern>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BannerFestival
