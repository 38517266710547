import { useEffect } from 'react'
import SquareImageNameTitle from '@/components/Carousel/Templates/SquareImageNameTitle'
import PagerButton from '@/components/Carousel/Components/PagerButton'
import useCarousel from '@/components/Carousel/useCarousel'
import SquareImageNameRange from '@/components/Carousel/Templates/SquareImageNameRange'
import RectangleImageNameRange from '@/components/Carousel/Templates/RectangleImageNameRange'
import PagerLines from '@/components/Carousel/Components/PagerLines'
import useSwipe from '@/components/Carousel/useSwipe'
import { CarouselGridTemplateType, CarouselItem, CarouselProps } from '@/components/Carousel/types'

const Grid = (props: CarouselProps) => {
  const {
    sliderRef,
    pageNumber,
    currentPage,
    setBreakpoints,
    next,
    prev,
    move,
    isAtStart,
    isAtEnd,
    isActive
  } = useCarousel()
  const { handleTouchStart, handleTouchMove } = useSwipe()

  useEffect(() => {
    setBreakpoints({ xs: 1, sm: 2, md: 2, lg: 3, xl: 3, '2xl': 3 })
  }, [])

  const renderTemplate = (templateType: CarouselGridTemplateType, item: CarouselItem) => {
    if (templateType === 'SQUARE_IMAGE_NAME_TITLE') {
      return <SquareImageNameTitle {...item} />
    }
    if (templateType === 'SQUARE_IMAGE_NAME_RANGE') {
      return <SquareImageNameRange {...item} />
    }
    if (templateType === 'RECTANGLE_IMAGE_NAME_RANGE') {
      return <RectangleImageNameRange {...item} />
    }
  }

  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={(e) => handleTouchMove(e, prev, next)}
      className="carousel__layout--grid w-full xl:w-3/4"
      ref={sliderRef}
    >
      <h2 className="font-gilroy font-extrabold text-4xl sm:text-5xl text-center max-w-2xl ml-auto mr-auto mb-10">
        {props.title}
      </h2>
      <div className="flex flex-row gap-2 w-full">
        <div className="carousel__pager--left justify-center items-center hidden sm:flex">
          <PagerButton
            onClick={() => (!isAtStart() ? prev() : null)}
            type="PREV"
            disabled={isAtStart()}
          />
        </div>
        <div className="carousel__container w-full relative overflow-hidden">
          <div className="carousel__items-container absolute flex flex-row transition-left ease-in-out duration-500">
            {props.items.map((item: CarouselItem) => {
              return (
                <div className="carousel-item" key={item.title}>
                  {renderTemplate(props.template, item)}
                </div>
              )
            })}
          </div>
        </div>
        <div className="carousel__pager--right justify-center items-center hidden sm:flex">
          <PagerButton
            onClick={() => (!isAtEnd() ? next() : null)}
            type="NEXT"
            disabled={isAtEnd()}
          />
        </div>
      </div>
      <div className="carousel__page--dots flex flex-row gap-2 mt-16 justify-center">
        <PagerLines
          onClick={(_, index: number) => (!isActive(index, currentPage) ? move(index) : null)}
          theme={props.theme}
          pageNumber={pageNumber}
          currentPage={currentPage}
        />
      </div>
    </div>
  )
}

export default Grid
