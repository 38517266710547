import ListItem from '@/components/ArticleList/Templates/HighlightedWithShortList/ListItem'

import { Article, HighlightedWidthShortListProps } from '@/components/ArticleList/types'

// TODO: refactor this component to be reuseable for HighlightedWidthShortList component

const ShortListOnly = (props: HighlightedWidthShortListProps) => {
  return (
    <>
      {props.articles.length && (
        <div className="highlighted-with-short-list">
          <h1 className="text-center mb-16 text-3xl sm:text-4xl lg:text-5xl font-gilroy font-extrabold">
            {props.title}
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-3 md:gap-8 lg:gap-12 xl:gap-24">
            {props.articles.map((article: Article, index) => (
              <ListItem key={index + article.title} article={article} />
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default ShortListOnly
