import Carousel from '@/components/Collection/Templates/StoryWithCarousel/Carousel'
import { CollectionProps } from '@/components/Collection/types'
import { Link } from '@/components/Link/Link'

const StoryWithCarousel = (props: CollectionProps) => {
  return (
    <div className="story-with-carousel-collection py-28 bg-gradient-to-l from-lightSkyBlue to-purple">
      <div className="container-padded">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-14 lg:gap-28">
          <div className="collection__description">
            <Link href={props.url || '#'} className="hover:underline">
              <h2 className="collection__title font-gilroy font-extrabold text-5xl leading-tight mb-5">
                {props.title}
              </h2>
            </Link>
            {props.lead && props.lead !== '' && (
              <p className="collection__lead font-roboto font-bold text-lg mb-5 text-justify">
                {props.lead}
              </p>
            )}
            {props.description && props.description !== '' && (
              <p className="collection__description font-roboto font-normal text-lg text-justify">
                {props.description}
              </p>
            )}
          </div>
          <div className="collection__carousel">
            <Carousel items={props.items} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoryWithCarousel
