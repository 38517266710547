import { CollectionProps } from '@/components/Collection/types'
import Tile from '@/components/Collection/Templates/Tiles/Tile'
import { Link } from '@/components/Link/Link'

const Tiles = (props: CollectionProps) => {
  return (
    <div className="tiles-collection py-28">
      <div className="container-padded">
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-12">
          <div className="collection__description">
            <Link href={props.url || '#'} className="hover:underline">
              <h2 className="collection__title font-gilroy font-extrabold text-4xl md:text-5xl lg:text-4xl xl:text-5xl leading-tight mb-5">
                {props.title}
              </h2>
            </Link>
            {props.lead && props.lead !== '' && (
              <p className="collection__lead font-roboto font-bold text-base xl:text-lg mb-6 text-justify">
                {props.lead}
              </p>
            )}
            {props.description && props.description !== '' && (
              <p className="collection__description font-roboto font-normal text-lg text-justify">
                {props.description}
              </p>
            )}
          </div>
          {props.items.map(
            (item, index) => index < 5 && <Tile key={index + item.title} {...item} />
          )}
        </div>
      </div>
    </div>
  )
}

export default Tiles
