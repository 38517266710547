import React, { MouseEvent } from 'react'

import Icon from '@/components/Icon'

export interface PageButtonProps {
  type: 'PREV' | 'NEXT'
  disabled: boolean
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
}

const PagerButton: React.FC<PageButtonProps> = ({ disabled, type, onClick }: PageButtonProps) => {
  const classes = {
    buttonClass: disabled
      ? 'bg-darkerGray text-lightGray disabled cursor-default'
      : 'bg-gradient-to-b from-skyBlue to-indigo cursor-pointer',
    iconClass: disabled ? 'opacity-30' : ''
  }

  const iconName = {
    PREV: 'arrow-left-small',
    NEXT: 'arrow-right-small'
  }

  return (
    <button
      className={`${classes.buttonClass} w-11 h-11 p-2 rounded-full font-semibold shadow-md transition-all`}
      onClick={onClick}
    >
      <Icon
        name={iconName[type]}
        disableHover={disabled}
        className={`${classes.iconClass} w-5 h-5 inline-block m-1`}
      />
    </button>
  )
}

export default PagerButton
